






import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import Input from "./Input";

@Component
export default class ColorInput extends Input {
    protected type = "text";
}
