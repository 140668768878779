






import Vue from "vue";
import Component from "vue-class-component";
import Dome from "./pages/Dome.vue";

@Component({components: {Dome}})
export default class App extends Vue {}
